<template>
  <div class="apply">
    <div class="bgc"></div>
    <div class="card_box">
      <div class="choose">
        您希望了解：
        <el-checkbox-group v-model="c_info.info_type">
          <el-checkbox label="MNSS安全综合服务平台"></el-checkbox>
          <el-checkbox label="信息安全专项服务"></el-checkbox>
           <el-checkbox label="定制行业解决方案"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="client_ino">
        <div>赛虎科技，网络安全风险解决服务提供商</div>
        <el-form label-position="right" label-width="70px" :model="c_info" ref="c_info" :rules="rules">
          <el-form-item prop="name">
            <label slot="label">姓&emsp;名</label>
            <el-input v-model="c_info.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="c_info.phone"></el-input>
          </el-form-item>
          <el-form-item label="公司名">
            <el-input v-model="c_info.customer_name"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" round @click="submit">提&emsp;&emsp;交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    var checkPhone = (rule,value,callback) => {
      var phoneReg = /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/;
      if (!phoneReg.test(value)) {
        callback(new Error('请输入有效的手机号码！'));
      }else{
        callback()
      }
    }
    return {
      c_info:{
        info_type:[],
        customer_name:''
      },
      rules: {
        name:[{required: true,message:'必填', trigger: 'blur'}],
        phone:[
          {required: true, message:'必填',trigger: 'blur'},
          {validator:checkPhone,trigger: 'blur'}
        ],
      },
    }
  },
  methods:{
    submit(){
      this.$refs.c_info.validate(valid =>{
        if(valid){
          this.$http({
            method:'post',
            url: '/add_info',
            data: this.c_info,
          }).then(res => {
            console.log(res)
            if(res.data.code == 200){
              this.$message.success('提交成功！我们会尽快与您联系！');
            }else{
              this.$message.error(res.data.msg)
            } 
          }).catch(error=>{
            console.log(error);
            // this.getvcode();
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .apply{
    position: relative;
    .el-checkbox__input.is-checked+.el-checkbox__label{
      color: $dark-base;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner{
      color: $dark-base;
      background-color:$dark-base;
      border-color:$dark-base;
    }
    .el-button--primary{
      width: 100%;
      background-color: $dark-base;
      border-color: $dark-base;
    }
    .bgc{
      width: 100%;
      height: 400px;
      background-color: $dark-base;
    }
    .card_box{
      position: absolute;
      top: 150px;
      left:calc(50% - 457px);
      background-color: #fff;
      width: 914px;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 0px 0px 5px #808080;
      display: flex;
      font-size: 24px;
      .choose{
        padding: 60px;
        border-radius: 10px;
        background-image: linear-gradient(153deg,#e5f2fe,#9bb7e6);
        .el-checkbox-group{
          margin-top: 50px;
          .el-checkbox{
            display: block;
            margin-top: 40px;  
            margin-right: unset;       
            .el-checkbox__label{
              font-size: 20px;
            }
          }
        }
      }
      .client_ino{
        padding: 60px;
        text-align: center;
        .el-form{
          margin-top: 50px;
          .el-form-item{
            margin-bottom: 30px;
          }
        }
      }
    }
    @media screen and (max-width: 1080px){
      .bgc{
        height: 40px;
      }
      .card_box{
        width: 100%;
        position: unset;
        .choose{
          display: none;
        }
        .client_ino{
          padding: 40px;
        }
      }
    }
  }
</style>